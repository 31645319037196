@import "mixins";
@import "fonts";
@import "vendor";
@import "vars";
@import "settings";
// @import "~bootstrap/scss/bootstrap";


* {
    // font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    color: $text-main-color;
    text-decoration: none;
}

.section-rooms-page {
    padding-top: 60px;
}
.rooms-container {
    box-sizing: border-box;
    max-width: 1359px;
    width: 100%;
    margin: 0 auto;
}
.section-rooms__headline {
    max-width: 887px;
    width: 100%;
    margin: 0 auto  60px;
}
.rooms__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 309px);
    gap: 40px;
    justify-content: center;
}
.rooms__list__item {
    position: relative;
    background-image: url('../img/item1.jpg');
    background-image: url('../img/item2.jpg');
    background-image: url('../img/item3.jpg');
    background-image: url('../img/item4.jpg');
    background-image: url('../img/item5.jpg');
    background-image: url('../img/item6.jpg');
    background-image: url('../img/item7.jpg');
    background-image: url('../img/item8.jpg');
    background-image: url('../img/item9.jpg');
    background-image: url('../img/item10.jpg');
    background-image: url('../img/item11.jpg');
    background-image: url('../img/item12.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 540px;
    padding: 0 24px 20px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    overflow: hidden;

    &:hover .rooms__list__item__link {
        height: auto;
        padding: 9px 15px;
        color: #fff;
        background: #478F55;

    }
}
.rooms__list__item__wrapper {

}
.rooms__list__item__name {
    font-family: 'Spectral';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    /* White */
    color: #FFFFFF;
    /* 1тень */
    text-shadow: 0px 0px 15px rgba(0, 73, 174, 0.4);
    margin-bottom: 15px;
    display: block;
}
.rooms__list__item__desc {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-shadow: 0px 0px 15px rgba(0, 73, 174, 0.4);

}
.rooms__list__item__link {
    background: transparent;
    border-radius: 5px;
    padding: 0 15px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: -0.02em;
    /* White */
    color:transparent;

    display: inline-block;
    margin-top: 15px;
    transition:  all .3s ease-in-out;
}

.section-rooms-main {
margin-top: 300px;
}
.rooms-container {

}
.section-rooms__wrap {
    position: relative;
}
.section-rooms__heading {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 60px;
    text-align: center;
    color: #75685D;
    margin-bottom: 50px;
}
.section-rooms__desc {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    /* or 91% */
    text-align: center;
    /* main 1 */
    color: #75685D;
    max-width: 887px;
    margin: 0 auto 60px;
}
.section-rooms__arrows{
    position: absolute;
    right: 0;
    bottom: 0;
}
.section-rooms-slider__btn {

}
.section-rooms__slider-wrap {

}
.section-rooms__slider {

}
.section-rooms__slider__item {
    position: relative;
    background-image: url(../../assets/img/item1.jpg);
    background-image: url(../../assets/img/item2.jpg);
    background-image: url(../../assets/img/item3.jpg);
    background-image: url(../../assets/img/item4.jpg);
    background-image: url(../../assets/img/item5.jpg);
    background-image: url(../../assets/img/item6.jpg);
    background-image: url(../../assets/img/item7.jpg);
    background-image: url(../../assets/img/item8.jpg);
    background-image: url(../../assets/img/item9.jpg);
    background-image: url(../../assets/img/item10.jpg);
    background-image: url(../../assets/img/item11.jpg);
    background-image: url(../../assets/img/item12.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 540px;
    padding: 0 24px 20px;
    display: flex !important;
    justify-content: end;
    align-items: flex-end;
    overflow: hidden;
    margin: 0 20px;

    &:hover .rooms__list__item__link {
        height: auto;
        padding: 9px 15px;
        color: #fff;
        background: #478F55;

    }
}