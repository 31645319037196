//Тут происходят вызовы шрифтов
// @font-face {
//     font-family: 'Inter';
//     src: url('../fonts/Inter-SemiBold.ttf');
//     // font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'ZenAntique';
//     src: url('../fonts/ZenAntique-Regular.ttf');
//     font-weight: noraml;
//     font-style: normal;
// }
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Italic-VariableFont_wght.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-VariableFont_wght.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
